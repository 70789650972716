import React from "react";

import { Layout, SEO } from "../../components/structure";
import {
  CallToAction,
  Content,
  Hero,
  IconTile,
  Button,
  StaggeredColumn,
  Grid,
  Column,
} from "../../components/blocks";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import { mq, brand } from "../../styles";
import english from "../../../content/translations/en-CA/contact.json";

const ContactPage = () => {
  let lang = english;

  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "GettyImages-550215717.jpg" }) {
        childImageSharp {
          fixed(width: 750) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      map: file(base: { eq: "map.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      america: file(base: { eq: "america.png" }) {
        ...LocationTileImage
      }
      dubai: file(base: { eq: "dubai.png" }) {
        ...LocationTileImage
      }
      singapore: file(base: { eq: "singapore.png" }) {
        ...LocationTileImage
      }
      uk: file(base: { eq: "uk.png" }) {
        ...LocationTileImage
      }
      icon_partnership: file(base: { eq: "icon-17.png" }) {
        ...IconTileImage
      }
      icon_businessDevelopment: file(base: { eq: "icon-19.png" }) {
        ...IconTileImage
      }
      icon_investorRelations: file(base: { eq: "icon-20.png" }) {
        ...IconTileImage
      }
      icon_mediaRelations: file(base: { eq: "icon-18.png" }) {
        ...IconTileImage
      }
      icon_customerSupport: file(base: { eq: "icon-24.png" }) {
        ...IconTileImage
      }
    }
  `);

  return (
    <Layout>
      <SEO title={lang.meta.title} description={lang.meta.description} />
      <Hero title={lang.hero.title} image={data["heroImg"]} />
      <Content greyed>
        <div
          style={{
            width: "100%",
            backgroundColor: "#ffffff",
            padding: "30px 0 20px 0",
          }}
        >
          <div style={{ maxWidth: "1250px", margin: "auto" }}>
            <h2 className={"kl"}>{lang.content.intro_section_one.title}</h2>
            <p style={{ marginBottom: "1rem" }}>
              {lang.content.intro_section_one.copy}
            </p>
          </div>
        </div>
        <StaggeredColumn style={{ marginTop: "2rem" }}>
          <IconTile
            card
            title={lang.content.contact_tile_one.title}
            icon={data["icon_partnership"]}
          >
            <h5 style={{ marginBottom: "25px" }}>
              {lang.content.contact_tile_one.header}
            </h5>
            <div>{lang.content.contact_tile_one.role}</div>
            <div>
              <a href={"mailto:" + lang.content.contact_tile_one.contact}>
                {lang.content.contact_tile_one.contact}
              </a>
            </div>
          </IconTile>
          <IconTile
            card
            title={lang.content.contact_tile_two.title}
            icon={data["icon_businessDevelopment"]}
          >
            <h5 style={{ marginBottom: "25px" }}>
              {lang.content.contact_tile_two.header}
            </h5>
            <div>{lang.content.contact_tile_two.role}</div>
            <div>
              <a href={"mailto:" + lang.content.contact_tile_one.contact}>
                {lang.content.contact_tile_two.contact}
              </a>
            </div>
          </IconTile>
          <IconTile
            card
            title={lang.content.contact_tile_three.title}
            icon={data["icon_mediaRelations"]}
          >
            <h5 style={{ marginBottom: "25px" }}>
              {lang.content.contact_tile_three.header}
            </h5>
            <div>{lang.content.contact_tile_three.role}</div>
            <div>
              <a href={"mailto:" + lang.content.contact_tile_three.contact}>
                {lang.content.contact_tile_three.contact}
              </a>
            </div>
          </IconTile>
          <IconTile
            CSTile
            card
            title={lang.content.contact_tile_four.title}
            icon={data["icon_customerSupport"]}
          >
            <div>{lang.content.contact_tile_four.role}</div>
            <div>
              <a href={lang.content.contact_tile_four.contact}>
                {lang.content.contact_tile_four.label}
              </a>
            </div>
          </IconTile>
        </StaggeredColumn>

        <Content>
          <Grid columns={2}>
            <Column>
              <h3 style={{ textAlign: "left" }}>{lang.content.office.title}</h3>
              <p style={{ textAlign: "left" }}>{lang.content.office.copy}</p>
              <address style={{ marginBottom: 0 }}>
                {lang.content.office.address_line_one}
                <br />
                {lang.content.office.address_line_two}
              </address>
              <ul style={{ listStyle: "none", padding: 0, margin: "0 0 2rem" }}>
                <li>
                  T.{" "}
                  <a
                    href={"tel:+1" + lang.content.office.telephone}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {lang.content.office.telephone}
                  </a>
                </li>
                <li>F. {lang.content.office.fax}</li>
              </ul>
            </Column>
            <Column>
              <a
                style={{ display: "block", marginBottom: "2rem" }}
                rel={"noreferrer nofollow"}
                href="https://www.google.com/maps/place/Points+International+Ltd./@43.6506858,-79.3839988,16z/data=!4m5!3m4!1s0x882b34cff0b1f21d:0xbfac4c96de7c1e4c!8m2!3d43.650352!4d-79.383795"
              >
                <GatsbyImage fluid={data["map"].childImageSharp.fluid} />
              </a>
            </Column>
          </Grid>
        </Content>

        <div
          className={"location-bg"}
          style={{
            backgroundColor: "#f5f5f7",
            paddingTop: "40px",
            paddingBottom: "50px",
            margin: "auto",
            maxWidth: "1050px",
          }}
        >
          <h3 style={{ marginBottom: "40px" }}>
            {lang.content.office.location_header}
          </h3>
          <LocationWrapper>
            <div>
              <GatsbyImage fluid={data["uk"].childImageSharp.fluid} />
              <div>London, UK</div>
            </div>

            <div>
              <GatsbyImage fluid={data["dubai"].childImageSharp.fluid} />
              <div>Dubai, UAE</div>
            </div>

            <div>
              <GatsbyImage fluid={data["singapore"].childImageSharp.fluid} />
              <div>Singapore</div>
            </div>
          </LocationWrapper>
        </div>
      </Content>
      <CallToAction title={lang.content.call_to_action.title}>
        <p>{lang.content.call_to_action.copy}</p>
        <Button
          destination={"/contact/schedule-a-call/"}
          type={"white"}
          label={lang.content.call_to_action.buttonText}
        />
      </CallToAction>
    </Layout>
  );
};

const LocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 2rem;

  @media only screen and ${mq.maxMd} {
  }

  > div {
    flex-basis: 24%;
    overflow: hidden;
    margin-bottom: 2rem;
    border-radius: 25px;
    padding: 40px 15px;
    background-color: #ffffff;

    @media only screen and ${mq.maxMd} {
      padding: 20px 5px;
    }
    @media only screen and ${mq.maxSm} {
      flex-basis: 49%;
      padding: 5px;
    }

    .gatsby-image-wrapper {
      max-width: 100%;
    }

    div {
      font-size: 18px;
      font-weight: 800;
      margin-top: 20px;

      @media only screen and ${mq.maxMd} {
        margin-top: 5px;
      }
    }

    @media screen and ${mq.minMd} {
      flex-basis: 24%;
      margin-bottom: 0;
    }
  }
`;

export const query = graphql`
  fragment LocationTileImage on File {
    childImageSharp {
      fluid(maxWidth: 450, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default ContactPage;
